import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import DownloadDropdown from './DownloadDropdown'
import css from './SecondaryNav.module.scss'
import { getPreviousPath, pushDataLayerEventHandler } from '@/utils/utils'
import UCookies from 'universal-cookie'
import dynamic from 'next/dynamic'
const IconoGraphy = dynamic(()=>import('../IconoGraphy')) 

const SecondaryNav = ({
  metaData,
  propertyDetails,
  stickyHeader,
  page,
  setRevampNavOpen,
  showStickyMobileHeader
}) => {
  // Dropdown states
  const [showDropdown, setShowDropdown] = useState(false)
  const [showProfileIcon, setShowProfileIcon] = useState(false)
  const { t, lang } = useTranslation('translations-auction')
  const router = useRouter()
  let fillColor = 'color_grey_dim'
  const cookies = new UCookies()
  const authToken = cookies.get('authToken')
  const authUserId = cookies.get('userId')

  useEffect(() => {
    setShowProfileIcon(true)
  }, [])

  const refPath = () => {
    if (page == 'propertyDetail' && propertyDetails?.propertyInfo) {
      if (lang === 'ar') {
        return `/en/${propertyDetails.propertyInfo.propertyFor}/${propertyDetails.propertyInfo.alternateSlug}`
      } else if (lang === 'en') {
        return `/${propertyDetails.propertyInfo.propertyFor}/${propertyDetails.propertyInfo.alternateSlug}`
      }
    } else if (metaData) {
      if (metaData.alternateSlug) {
        if (lang === 'ar') {
          return `/en/${metaData.alternateSlug}`
        } 
        return `/${metaData.alternateSlug}`
      }
    } else {
      if (lang === 'ar') {
        return `/en${router.asPath}`
      } else if (lang === 'en') {
        return `${router.asPath}`
      }
    }
    return router.asPath
  }
  const profileclick = (e) => {
    e.stopPropagation()
    setRevampNavOpen(true)
  }

  return (
    <div className={`${css.navbar} ${showStickyMobileHeader ? css.isNotDesktop: ''}`}>
      <div className={css.navbar__item}>
        <div className={css.actionBtns}>
          <button
            className={css.downloadAppBtn}
            onClick={(e) => {
              e.stopPropagation()
              setShowDropdown(!showDropdown)
            }}
          > 
            <IconoGraphy iconClass={'icon-app-download'} iconColor={fillColor}></IconoGraphy>
            {t('globalNavigation.DOWNLOAD_APP')}
          </button>
          {showDropdown && (
            <DownloadDropdown
              onClose={() => {
                setShowDropdown(!showDropdown)
              }}
            />
          )}
        </div>
        <div
          onClick={profileclick}
        >
          <div className={`${css.profile} ${!stickyHeader ? css.profileHoverFixonDarkBG : ''}`}>
            <IconoGraphy iconClass={'icon-menu-more'} iconColor={fillColor}></IconoGraphy>
            <IconoGraphy
              iconClass={authToken && authUserId && showProfileIcon ? 'icon-loggedin-profile' : 'icon-profile'}
              iconColor={fillColor}
            ></IconoGraphy>
          </div>
        </div>
        <div className={css.language}>
          <a
            href={refPath()}
            className={css.navbar__link}
            dir={lang === 'ar' ? 'ltr' : 'rtl'}
            onClick={() =>
              pushDataLayerEventHandler(window, lang, 'auction_ADP_more_menu_lang', '', {
                source: getPreviousPath(),
              })
            }
          >
            <IconoGraphy iconClass={'icon-globe'} iconColor={fillColor}></IconoGraphy>
            {t('common.LANGUAGE')}
          </a>
        </div>
      </div>
    </div>
  )
}

export default SecondaryNav
